<template>
  <div class="wrap selfDetail" style="min-height: 100vh;background: #F8F8F8;">   
    <van-notice-bar
      v-if="isEnable=='1'"
      wrapable
      :scrollable="false"
      :text="'使用时间为'+startTime+'-'+endTime+',请在规定时间内完成支付'"
    />
    <van-notice-bar
      v-if="isEnable=='0'"
      class="noticeClose"
      wrapable
      :scrollable="false"
    />
    <div class="content" style="background-color: #fff;">
      <van-row>
        <van-col span="24" style="display:flex;justify-content:space-between">合同号：{{list.contract_number}}
          <van-tag plain round type="primary" @click="handlePlan">租金计划</van-tag>
        </van-col>
      </van-row>
    </div>
    <div class="content1">
      <div style="border-bottom:1px solid #eee;padding:15px 10px 5px 10px;background-color: #fff;">
        <div style="font-size:14px;margin-bottom: 15px;">
          <span style="color:#666;display: inline-block;margin-right: 5px;">本次应还</span>
          <span v-if="showinput1" style="color:#ee0a24">{{ list.pay_total }}</span>
        </div>
        <van-form v-if="showinput" ref="ruleForm">
          <van-field  
            class="inputmount" 
            v-model="paymount" 
            ref="paymount"
            type="number" 
            :formatter="formatterAmount"
            @blur="onSubmit"
            placeholder="请输入金额"
            :error="false"
            :rules="[
              {validator:validatorAmount0,message: '支付金额不能低于1元'},
              {validator:validatorAmount3,message: '支付金额不得高于本次应还金额'},
              {validator:validatorAmount1,message: '请填写正确的格式'},
              {validator:validatorAmount2,message: '请输入2位小数'}
            ]" 
          >
            <template #button>
              <van-button plain type="primary" @click="onSubmit" size="normal" color="#FF7C3F" style="border: 0px;">完成</van-button>
            </template>
          </van-field>
        </van-form>
        <div v-else style="color:#333;" @click="handleShowinput">
          <span style="font-size:36px;display: inline-block;margin-right: 5px;">{{paymountInput | formatMoney(2)}}</span>
          <van-icon v-if="Number(list.pay_total)>0" name="edit" size="25" color="#666" />
        </div>
      </div>
      <div class="detail-wrap" id="detail-wrap" v-if="Number(list.pay_total)>0">
        <van-collapse :border="false" v-model="activeNames" @change="hanndleChange">
          <van-collapse-item 
            :border="false" 
            title="应还明细" 
            name="1" 
            :value="detailText"
            ref="collapseItem"
          >
          <div v-for="(item,index) in payList" :key="index" class="detailcell">
            <van-cell :center="true">
              <template #title>
                <div class="contentBetween">
                  <span class="custom-title" style="font-size:14px;color:#323233;">{{`第${item.planlist}期`}}</span>
                  <van-tag v-if="item.status=='未到期'" round color="#ECECEC" text-color="#666666" class="custom-status1">{{item.status}}</van-tag>
                  <van-tag v-if="item.status=='未还款'" round color="#FBD9DC" text-color="#E61223" class="custom-status1">{{item.status}}</van-tag>
                  <van-tag v-if="item.status=='部分还款'" round color="#FFEDCE" text-color="#D35921" class="custom-status1">{{item.status}}</van-tag>
                </div>
              </template>
              <template #label>
                <div class="contentBetween" style="margin-top:10px">
                  <span class="custom-title" style="font-size:12px;color:#969799;">{{item.plan_date}}</span>
                  <span class="custom-title" style="font-size:14px;color:#969799;">{{item.planamount}}</span>
                </div>
              </template>
            </van-cell>
            </div>
          </van-collapse-item>
        </van-collapse>
      </div>
    </div>
    <!-- <van-popup
      v-model="show"
      round
      position="bottom"
      :style="{ height: '50%' }"
    >
      <div class="tipTitile">还款</div>
      <van-cell-group class="cel_money">
        <van-cell title="还款金额" :value="list.pay_total | formatMoney(2)" />
      </van-cell-group>
      <div class="footerBox" @click="confirm">
        <div class="footerBtn">确定</div>
      </div>
    </van-popup> -->
    <van-popup
      v-model="showVanpopup"
      :close-on-click-overlay="false"
      round
      position="bottom"
      :style="{ height: '290px' }"
    >
    <div class="tipTitile">提示</div>
      <div class="tipContent" style="margin-bottom:70px">
        <ul>
          <li v-if="list.ifearlier == '是'">此合同在提前结清流程中无法支付，如有疑问请咨询我司客服<a class="linka" href="tel:4000011255">4000011255</a></li>
          <li v-if="list.pay_fee == '是'">您存在未缴纳的诉讼/拖车费用,无法自助还款,请拨打<a class="linka" href="tel:4000011255">4000011255</a>进行咨询</li>
        </ul>
      </div>
      <div class="footerBox" @click="confirmKnow">
        <div class="footerBtn">我知道了</div>
      </div>
    </van-popup>
    <van-popup
      v-model="showkoukuantip"
      :close-on-click-overlay="false"
      round
      position="bottom"
      :style="{ height: '300px' }"
    >
    <div class="tipTitile">重要提示</div>
      <div style="margin-left:20px;margin-top:20px;color:#333;font-weight:bold;">提示内容</div>
      <div class="tipContent" style="margin-bottom:70px;margin-top:10px;">
        <ul>
          <li>您好，我司正在操作银行卡扣款，如您选择微信自助还款，请确保扣款银行卡余额不要大于当月还款金额，防止重复还款。</li>
        </ul>
      </div>
      <van-row class="footerBox" style="font-weight:bold;">
        <van-col span="12" style="border-right:1px solid #ECECEC">
          <van-button type="text" style="font-size:0.3rem;color:#333;" @click="backto">返回</van-button>
        </van-col>
        <van-col span="12">
          <van-button type="text" style="font-size:0.3rem;color:#333;" @click="payfor">继续支付</van-button>
        </van-col>
      </van-row>
    </van-popup>
    <van-popup
      v-model="planShow"
      closeable
      close-icon-position="top-left"
      round
      position="bottom"
      :style="{ minHeight: '50%',maxHeight: '80%',overflow:'hidden',paddingBottom:'20px' }"
    >
      <div class="tipTitile" style="border-bottom:0px">租金计划</div>
      <div class="popup-wrap">
        <div v-for="(v,id) in planList" :key="id" class="detailcell1">
          <div v-for="(item,index) in v.data" :key="index" class="detailcell">
            <van-cell :center="true">
              <template #title>
                <div class="contentBetween">
                  <span class="custom-title" style="font-size:14px;color:#323233;">{{item.period}}</span>
                  <van-tag v-if="item.pay_status=='已还款'" round color="#E2F9ED" text-color="#1F8D22" class="custom-status1">已还款</van-tag>
                  <van-tag v-if="item.pay_status=='未还款'" round color="#FBD9DC" text-color="#E61223" class="custom-status1">未还款</van-tag>
                  <van-tag v-if="item.pay_status=='部分还款'" round color="#FFEDCE" text-color="#D35921" class="custom-status1">部分还款</van-tag>
                </div>
              </template>
              <template #label>
                <div class="contentBetween" style="margin-top:10px">
                  <span class="custom-title" style="font-size:12px;color:#969799;">{{item.plan_day}}</span>
                  <span class="custom-title" style="font-size:15px;color:#333;">{{item.amount}}</span>
                </div>
              </template>
            </van-cell>
          </div>
        </div>
      </div>
    </van-popup>
    <div class="bottomDiv">
      <div class="tips">
        <p>温馨提示：</p>
        <p>1、请确保还款银行卡中不要存款，防止重复扣款。 </p>
        <p>2、还款成功后预计1-2个小时更新公众号中还款状态。 </p>
      </div>
      <van-col span="20" offset="2">
        <van-button :loading='isLoading' round block type="danger" loading-type="spinner" @click="submit">
          立即还款
        </van-button>
      </van-col>
    </div>
  </div>
</template>
<script>
  import {getImgToken,toOrder,findOrderPayStatus,payinfopage,findPayConfingDetail,rentPlanList,payinfodetail} from '@/server'
  import { Toast,Dialog } from 'vant';
  import { formatMoney } from '@/lib/format';
  import {setCookie,getCookie} from '@/lib/utils';
  import wx from 'weixin-js-sdk';
  export default {
  data() {
    return {
      showVanpopup:false,//判断是否提前结清流程中，若有则为true显示提示框
      showkoukuantip:false,//判断是否在核销中，是否显示重复扣款的提示
      contractNo: this.$store.state.settQueryContDetail.contractNo,
      conType: this.$store.state.settQueryContDetail.conType,
      openId:getCookie('openId'),
      code:this.$route.query.code || '',
      list:'',
      show:false,
      planShow:false,
      orderList:{},
      isLoading:false,
      startTime:'--',
      endTime:'--',
      isEnable:'0',
      paymount:'',
      showinput:false,
      showinput1:false,
      activeNames: [],
      detailText:'展开',
      planList:[],
      payList:[],
      paymountInput:null
    };
  },
  filters:{
    //格式化金额
    formatMoney(value, dot, digits){
      if(value == null){
        return ''
      }else{
        return "￥"+formatMoney(value, dot, digits)
      }
    },
  },
  mounted(){
    let that = this;
    sessionStorage.setItem('key', 'selfRepay');
    window.addEventListener("popstate", function(e) {  //回调函数中实现需要的功能
    	if(sessionStorage.getItem('key') == 'selfRepay') {
        that.$router.push({ path: '/selfRepayment' });
      }
    }, false);
  },
  created(){
    this.getDetail();
    this.getPaymentDetail();
    this.getImgToken();
  },
  methods: {
    //获取token
    getImgToken(){
      getImgToken({openid:this.openId}).then(res=>{
        if(res.wx_code=='0'){
          localStorage.setItem("token", res.img_token); 
          this.getTimeDetail();
        }
      })
    },
    //页面返显时间
    getTimeDetail(){
      findPayConfingDetail().then(res=>{
        if(res.status=='200'){
          this.startTime = res.data.startTime;
          this.endTime = res.data.endTime;
          this.isEnable = res.data.enable;
        }else{
          Toast.fail({
            message: res.msg,
            duration:'3000'
          });
        }
      })
    },
    getDetail(){
      const params = {
        contract_number:this.contractNo,
        con_type:this.conType
      }
      payinfopage(params).then(res=>{
        if(res.status=='200'){
          this.list = res;
          this.paymount = this.list.pay_total;
          this.paymountInput = this.list.pay_total;
        }else{
          Toast.fail({
            message: res.msg,
            duration:'3000'
          });
        }
      })
    },
    getPaymentDetail(){
      const params = {
        contract_number:this.contractNo
      }
      payinfodetail(params).then(res=>{
        if(res.status=='200'){
          this.payList = res.data;
        }else{
          Toast.fail({
            message: res.msg,
            duration:'3000'
          });
        }
      })
    },
    formatterAmount(value) {
      // 输入内容只能为数字，不允许输入字母、空格、特殊符号等； 
      return value.replace(/[^0-9.]/g,"").replace(/(\.)(\d*)(\1*)/g,"$1$2");
    },
    validatorAmount1(val) {
      if(val.indexOf(".")== 0){//首位不能为小数点
        return false; 
      }else if(val!='0' && val.indexOf(".") == -1 && val.indexOf(0) == 0){//没有小数点且首位不能为0
        return false;
      }
    },
    validatorAmount2(val) {
      var i = val.substring(val.indexOf(".")+1).length;
      if(val.indexOf(".")>0 && i>2){//小数点后两位
        return false;
      }
    },
    validatorAmount3(val){
      if(Number(val)>Number(this.list.pay_total)){
        return false;
      }
    },
    validatorAmount0(val){
      if(!val || Number(val)<1){
        return false;
      }
    },
    onSubmit() {
      this.$refs.ruleForm.validate().then(() => {
        this.showinput = false;
        this.paymountInput = this.paymount;
      })
    },
    handlePlan(){
      const params = {
        contract_number:this.contractNo
      }
      rentPlanList(params).then(res=>{
        if(res.status=='200'){
          this.planList = res.data;
          this.planShow = true;
        }else{
          Toast.fail({
            message: res.msg,
            duration:'3000'
          });
        }
      })
      
    },
    backto(){
      this.showkoukuantip = false;
    },
    payfor(){
      this.showkoukuantip = false;
      // this.show = true;//弹起金额弹层
      let that = this;
      Dialog.confirm({
        title: '支付金额',
        message: `<span class='messageStyle'>￥${Number(this.paymount).toFixed(2)}</span>`,
        confirmButtonText:'确认支付',
        confirmButtonColor:'#fff',
        getContainer:'.selfDetail'
      }).then(() => {
        that.confirm()
      }).catch(() => {
        // on cancel
      });
    },
    confirmKnow(){
      this.showVanpopup = false;
    },
    handleShowinput(){//点击显示input
      if(Number(this.list.pay_total) == 0){
        this.showinput = false;
      }else{
        this.showinput1 = true;
        this.showinput = true;
        this.paymount = '';
        // setTimeout(()=>{
        //   this.$refs.paymount.focus()
        // },500)
        this.$nextTick(()=>{
          this.$refs.paymount.focus()
        })
      }
    },
    paymentSubmit(){
      if(this.list.pay_fee=='是'){
        this.showVanpopup = true;
      }else if(this.list.ifearlier=='是'){
        this.showVanpopup = true;
      }else{
        this.getOrderList();//调起下单接口
      }
    },
    submit(){//立即还款
      if(Number(this.list.pay_total)==0){
        Dialog.alert({
          message: '您本次金额已还完，无需还款',
        })
      }else{
        if(this.showinput){
          this.$refs.ruleForm.validate().then(() => {
            this.paymentSubmit();
          })
        }else{
          this.paymentSubmit();
        }
      }
    },
    hanndleChange(value){
      if(value[0]){
        this.detailText = "收起";
      }else{
        this.detailText = "展开";
      }
      // console.log(value)
    },
    //统一下单接口
    getOrderList(){
      this.isLoading = true;
      const params = {
        contractCode:this.contractNo,
        amount:Number(this.paymount).toFixed(2),
        plans:this.list.terms_list,
        leaseType:this.conType=='zhizu' ? 0 : this.conType=='huizu' ? 1 : '',
        payerId:this.openId,
        payerName:this.list.payer_name,
        payScenario:1 //-支付场景 1：JSAPI支付
      }
      toOrder(params).then(res=>{
        if(res.status=='200'){
          this.isLoading = false;
          this.orderList = res.data;
          if(res.data.isPay>0){
            this.showkoukuantip = true;
          }else{
            this.showkoukuantip = false;
            let that = this;
            // this.show = true;//弹起金额弹层
            Dialog.confirm({
              title: '支付金额',
              message: `<span class='messageStyle'>￥${Number(this.paymount).toFixed(2)}</span>`,
              confirmButtonText:'确认支付',
              confirmButtonColor:'#fff',
              getContainer:'.selfDetail'
            }).then(() => {
              that.confirm()
            }).catch(() => {
              // on cancel
            });
          }
        }else{
          this.isLoading = false;
          if(res.subCode=='5010'){
            Dialog.alert({
              message: '使用时间为<span style="color:#f00">'+res.subMsg+'</span>,\n请在规定时间内完成支付',
              dangerouslyUseHTMLString: true
            })
          }else if(res.subCode=='5019'){
            Dialog.alert({
              message: res.subMsg,
            })
          }else{
            if(res.subCode=='5017'){
              Dialog.alert({
                message:'支付已成功，预计1-2个小时更新还款状态，请稍后查看。',
              })
            }else{
              Toast.fail({
                message: res.subMsg,
                duration:'3000'
              });
            }
          }
        }
      })
    },
    //查询支付结果
    queryOrderPayStatus(){
      const params = {
        orderId:this.orderList.id
      }
      findOrderPayStatus(params).then(res=>{
        if(res.status=='200'){//--1.支付成功  2.支付未完成
          if(res.data.isPayOk=='1'){
            Toast("支付成功")
          }else if(res.data.isPayOk=='2'){
            Toast("支付失败，请稍后再试")
          }
        }else{
          Toast.fail({
            message: res.msg,
            duration:'3000'
          });
        }
      })
    },
    onBridgeReady() {
      let that = this;
      WeixinJSBridge.invoke('getBrandWCPayRequest', {
        "appId": "wxe40be82e1238dfe7",   //公众号ID，由商户传入    
        "timeStamp": this.orderList.timeStamp,   //时间戳，自1970年以来的秒数    
        "nonceStr": this.orderList.nonceStr,      //随机串    
        "package": this.orderList.cmbPackage,
        "signType": "RSA",     //微信签名方式：    
        "paySign": this.orderList.paySign //微信签名
      },
      (res)=>{
        if(res.err_msg == "get_brand_wcpay_request:ok") {
          // 使用以上方式判断前端返回,微信团队郑重提示：
          //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
          that.queryOrderPayStatus()
        }else if(res.err_msg == "get_brand_wcpay_request:cancel") {
          Toast("支付未完成")         
        
        }
      });
    },
    confirm(){
      if(typeof WeixinJSBridge == "undefined"){
        if(document.addEventListener){
          document.addEventListener('WeixinJSBridgeReady', this.onBridgeReady, false);
        }else if(document.attachEvent){
          document.attachEvent('WeixinJSBridgeReady', this.onBridgeReady);
          document.attachEvent('onWeixinJSBridgeReady', this.onBridgeReady);
        }
      }else{
        this.onBridgeReady()
        // this.show = false;
      } 
    }
  },
};
</script>
<style lang="less">
.contentBetween{
  display:flex;
  justify-content:space-between;
  align-items: center;
}
.inputmount{
  padding-left: 0px !important;
}
.inputmount .van-field__control{
  font-size: 24px !important;
}
.content{
  /* min-height: 200px; */
  margin: 10px;
  border-radius: 5px;
  padding: 20px 10px 20px 10px;
  font-size: 14px;
  color: #666;
}
.content1{
  margin: 10px;
  border-radius: 5px;
  font-size: 14px;
  color: #666;
}
.cont_row1{
  margin:20px;text-align:center;
}
.cont_row1 span{
  display:inline-block;margin-left:10px;
}
.cont_row2{
  text-align:center;line-height:25px;border-top: 1px solid #eee;
}
.cont_row2 .row2_col1{
  border-right: 1px solid #eee;
  padding: 8px;
}
.cont_row2 .row2_col2{
  padding: 8px;
}
.cont_row2 .ptwo{
  color:#333;font-weight:bold;
}
.cel_money .van-cell__value{
  color:#F00;
  font-size: 20px;
}
.tips{
  color:#ee0a24;
  font-size:13px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.tips p{
  margin: 6px 15px;
}
.tipTitile{
  color: #1A1A1A;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  border-bottom: 2px solid #eee;
  height: 50px;
  line-height: 50px;
  /* margin-top: 10px; */
}
.tipContent{
  background-color: #F5F5F5;
  margin: 20px 20px;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 0.3rem;
}
.tipContent ul{
  margin-left: 23px;
}
.tipContent li{
  list-style-type: disc;
  color: #333;
  /* font-size: 16px; */
  margin: 15px 0px;
}
.tipContent li .linka{
  text-decoration:underline;
  color: #247FFF;
  margin-right:5px;
  margin-left:5px;
}
.footerBox{
  background-color: #ffffff;
  padding: 0.2rem 0;
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
	position: fixed;
	bottom: 0;
}
.footerBtn {
  width: 6.7rem;
  height: 0.9rem;
  border-radius: 0.9rem;
  text-align: center;
  line-height: 0.9rem;
  background-color: #e50012;
  color: #ffffff;
}
.noticeClose{
  background-color:#F8F8F8;
}
.bottomDiv{
  position: fixed;
  padding-bottom: 10px;
  bottom: 0px;
  width: 100%;
  background-color: #fff;
}
.popup-wrap{
  min-height:50vh;
  max-height:80vh;
  overflow: auto;
  // padding-bottom: 20px;
  .detailcell1:last-child{
    .detailcell:last-child{
      padding-bottom: 70px;
    }
  }
}
.detailcell{
  border-bottom: 1px solid #eee;
}
.detail-wrap{
  .detailcell:last-child{
    padding-bottom: 180px;
  }
  .van-collapse-item__content{
    background-color: rgba(0,0,0,0);
    padding: 0px 0px 12px 0px;
  }
  // margin-top:5px;
  .van-cell{
    padding: 10px 10px;
  }
  // .custom-status{
  //   font-size: 11px;
  // }
  .custom-title {
    display:block;
    /* vertical-align: middle; */
  }
}
.messageStyle{
  color:#333;
  font-size:31px;
  margin-top:15px;
  display:block;
}
.selfDetail{
  .custom-status1{
    font-size: 9px;
    padding-right: 6px;
    padding-left: 6px;
    height: 16px;
  }
  .van-dialog__confirm{
    background-color: #e50012;
  }
}
</style>